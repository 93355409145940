import { createStore } from 'vuex'

// 判断登录状态
let logged: any = false, userData: any = null;
if(sessionStorage.getItem('userData')) {
  userData = sessionStorage.getItem('userData');
  logged = true;
}

export default createStore({
    state() {
      return {
        logged: logged, // 登录状态
        userData: userData, // 用户数据
        s_webSite: 'share.zsjykj.net',  // 项目域名
        appkey: 'enGfFZEx', // 资源中心课程详情视频key
        clientConfigure: {  // 阿里云配置项
          ossUrl: 'https://zskj-resourse-bucket.oss-cn-beijing.aliyuncs.com/', // 阿里云图片存储地址
          region: 'https://ylxue-bucket.oss-cn-beijing.aliyuncs.com/',
          endpoint: "https://oss-cn-beijing.aliyuncs.com", //节点
        },
      }
    },
    getters: {

    },
    mutations: {
      
    },
    actions: {

    },
    modules: {

    }
})
