import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//  样式重置文件
import './assets/reset.css'
//  上传图片时要用到的jquery解码文件
import './assets/jqb.js'

import ElementPlus from 'element-plus';
import 'dayjs/locale/zh-cn'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import 'element-plus/lib/theme-chalk/index.css';

import axios from 'axios'
import qs from 'qs'
import { ElMessage, ElLoading } from 'element-plus'
let loadingCount:any = 0;
let loadingInstance:any = null;
// 添加请求拦截器
axios.interceptors.request.use(function (config) {
    loadingCount++;
    // 给接口添加loading
    loadingInstance = ElLoading.service({
        text: '数据加载中......',
        background: 'rgba(255, 255, 255, .3)',
    });
    // 处理发送的参数
    config.data = qs.stringify(config.data);
    // 在发送请求之前做些什么
    return config;
}, function (error) {
    // 对请求错误做些什么
    ElMessage.error('数据请求失败!');
    return Promise.reject(error);
});
// 添加响应拦截器
axios.interceptors.response.use(function (response) {
    loadingCount--;
    if(loadingCount <= 0) {
        loadingInstance.close();
    }
    // 对响应数据做点什么
    return response;
}, function (error) {
    // 对响应错误做点什么
    ElMessage.error('数据请求失败!');
    return Promise.reject(error);
});

const app = createApp(App)

// 导入外部接口
import publicPath from './assets/publicPath'
// 把自定义接口挂载到实例上
app.config.globalProperties.$publicPath = publicPath;

app.use(ElementPlus, {locale}).use(store).use(router).mount('#app')
app.config.globalProperties.axios = axios
