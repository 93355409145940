import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import IndexView from '../views/Index.vue'

const routes: Array<RouteRecordRaw> = [
    // 测试页面
    {
        path: '/testView',
        component: () => import('../views/TestView.vue'),
    },
    // 首页
    {
        path: '/',
        component: IndexView,
    },
    // 未配置路径跳转到首页
    {
        path: '/:pathMatch(.*)',
        redirect: '/',
    },
    // 资源中心
    {
        path: '/resourceCenter',
        component: () => import('../views/ResourceCenter.vue'),
    },
    // 课程详情
    {
        path: '/courseDetails',
        component: () => import('../views/CourseDetails.vue'),
    },
    // 文档中心
    {
        path: '/documentCenter',
        component: () => import('../views/DocumentCenter.vue'),
    },
    // 文档详情
    {
        path: '/documentDetails',
        component: () => import('../views/DocumentDetails.vue'),
    },
    // 平台公告
    {
        path: '/platformAnnouncement',
        component: () => import('../views/PlatformAnnouncement.vue'),
    },
    // 公告详情
    {
        path: '/announcementDetails',
        component: () => import('../views/AnnouncementDetails.vue'),
    },
    // 联系我们
    {
        path: '/contactUs',
        component: () => import('../views/ContactUs.vue'),
    },
    // 入驻
    {
        path: '/company',
        component: () => import('../views/Company.vue'),
    },
    // 入驻详情
    {
        path: '/companyDetails',
        component: () => import('../views/CompanyDetails.vue'),
    },
    // 个人中心
    {
        path: '/userCenter',
        component: () => import('../views/UserCenter.vue'),
        children: [
            // 基本信息
            {
                path: '/basicInformation',
                component: () => import('../views/UserCenter/BasicInformation.vue'),
            },
            // 消息中心
            {
                path: '/messageCenter',
                component: () => import('../views/UserCenter/MessageCenter.vue'),
            },
            // 发布视频
            {
                path: '/postVideos',
                component: () => import('../views/UserCenter/PostVideos.vue'),
            },
            // 审核状态
            {
                path: '/auditStatus',
                component: () => import('../views/UserCenter/AuditStatus.vue'),
            },
            // 我的选课
            {
                path: '/courseSelection',
                component: () => import('../views/UserCenter/CourseSelection.vue'),
            },
            // 数据接口
            {
                path: '/dataInterface',
                component: () => import('../views/UserCenter/DataInterface.vue'),
            },
            // 资源使用统计
            {
                path: '/resourceGeStatistics',
                component: () => import('../views/UserCenter/ResourceGeStatistics.vue'),
            },
        ],
    },
]

const router = createRouter({
    // history: createWebHistory(process.env.BASE_URL),
    history: createWebHashHistory(),
    routes
})

export default router
